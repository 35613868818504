import Resource from '@/api/Resource'

const resource = new Resource('/api/claves-cuentas', 'clavesCuentasModule')

export default {
  namespaced: true,

  state: {
    list: [],

    isGettingResource: false
  },

  getters: {
    getList: state => state.list,

    getOptions: state => {
      if (!state.list) return []

      return state.list.map(f => ({ value: f.clave, text: f.clave }))
    },

    isListAvailable: state => {
      return !state.isGettingResource && state.list.length > 0
    }
  },

  mutations: {
    setList (state, payload) {
      state.list = payload
    },

    setIsGettingResource (state, payload) {
      state.isGettingResource = payload
    }
  },

  actions: {
    async getAll ({ commit }, periodo_fiscal_id) {
      const { error, data, message } = await resource.all(
        { periodo_fiscal_id: periodo_fiscal_id, formato: 'tree' },
        'setIsGettingResource'
      )

      if (error) return { error, message }

      commit('setList', data.data)

      return { data, message }
    }
  }
}
