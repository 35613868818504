import Resource from '@/api/Resource'
import service from '@/api/service'

const saldos = new Resource('/api/saldos', 'saldoModule')

export default {
  namespaced: true,

  state: {
    reporte: null,

    params: {
      periodo_fiscal_id: null,
      capitulo_id: null,
      fuente_financiamiento_id: null,
      proyecto_id: null,
      unidad_administrativa_id: null,
      partida_id: null,
      fecha_inicio: null,
      fecha_corte: null,
      modulo: null
    },

    currentPage: null,
    currentClave: null,

    isGettingResource: false
  },

  getters: {
    hasReporte: state => {
      return Boolean(state.reporte)
    },

    getReporte: ({ reporte }, getters) => {
      return getters.hasReporte ? reporte.data.saldos : []
    },

    getClaves: ({ reporte }, getters) => {
      return getters.hasReporte ? reporte.data.claves : []
    },

    getParams: state => {
      return state.params
    },

    getCurrentClave: state => {
      return state.currentClave
    },
    getCurrentPage: state => {
      return state.currentPage
    }
  },

  mutations: {
    resetState (state) {
      for (const key in state) {
        state[key] = null
      }
    },

    setReporte (state, reporte) {
      state.reporte = reporte
    },

    setIsGettingResource (state, payload) {
      state.isGettingResource = payload
    },

    setCurrentClave (state, payload) {
      state.currentClave = payload
    },
    setCurrentPage (state, payload) {
      state.currentPage = payload
    },

    setPeriodoFiscalId (state, payload) {
      state.params.periodo_fiscal_id = payload
    },
    setFechaInicio (state, payload) {
      console.log(payload)
      state.params.fecha_inicio = payload
    },
    setFechaCorte (state, payload) {
      state.params.fecha_corte = payload
    },
    setCapituloId (state, payload) {
      state.params.capitulo_id = payload
    },
    setFuenteFinanciamientoId (state, payload) {
      state.params.fuente_financiamiento_id = payload
    },
    setProyectoId (state, payload) {
      state.params.proyecto_id = payload
    },
    setUnidadAdministrativaId (state, payload) {
      state.params.unidad_administrativa_id = payload
    },
    setPartidaId (state, payload) {
      state.params.partida_id = payload
    },
    setModulo (state, payload) {
      state.params.modulo = payload
    }
  },

  actions: {
    async getReporteEstadoPptoEgresos ({ commit }, payload) {
      let retval = {}

      try {
        const { data } = await saldos.get('api/reportes/estado-egresos', {
          periodo_fiscal_id: payload.periodo_fiscal_id,
          capitulo_id: payload.capitulo_id,
          fuente_financiamiento_id: payload.fuente_financiamiento_id,
          proyecto_id: payload.proyecto_id,
          unidad_administrativa_id: payload.unidad_administrativa_id,
          partida_id: payload.partida_id,
          fecha_inicio: payload.fecha_inicio,
          fecha_corte: payload.fecha_corte,
          modulo: payload.modulo
        }, 'setIsGettingResource')

        retval = data

        commit('setReporte', data)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async exportReporteEstadoPptoEgresos ({ context }, payload) {
      let retval = {}

      try {
        // Duplicamos la llamada para verificar primero si no hay un
        // error y mostrar el mensaje correcto al usuario.
        // Hacemos la petición get sin especificar que se recibe un blob
        await service({
          _method: 'GET',
          url: 'api/reportes/estado-egresos/export',
          params: {
            periodo_fiscal_id: payload.periodo_fiscal_id,
            capitulo_id: payload.capitulo_id,
            fuente_financiamiento_id: payload.fuente_financiamiento_id,
            proyecto_id: payload.proyecto_id,
            unidad_administrativa_id: payload.unidad_administrativa_id,
            partida_id: payload.partida_id,
            fecha_inicio: payload.fecha_inicio,
            fecha_corte: payload.fecha_corte,
            modulo: payload.modulo
          }
        })
        // Si no existe error la excepción no se lanza y podemos
        // obtener el blob correctamente
        const { data } = await service({
          _method: 'GET',
          url: 'api/reportes/estado-egresos/export',
          responseType: 'blob',
          params: {
            periodo_fiscal_id: payload.periodo_fiscal_id,
            capitulo_id: payload.capitulo_id,
            fuente_financiamiento_id: payload.fuente_financiamiento_id,
            proyecto_id: payload.proyecto_id,
            unidad_administrativa_id: payload.unidad_administrativa_id,
            partida_id: payload.partida_id,
            fecha_inicio: payload.fecha_inicio,
            fecha_corte: payload.fecha_corte,
            modulo: payload.modulo
          }
        })
        retval = data
      } catch (error) {
        retval = { error: true, message: error.message, ...error.response.data }
      }

      return retval
    }
  }
}
