<template>
  <div class="mt-4 d-flex">
    <div class="w-100 d-flex justify-content-between">
      <!-- Al cancelar -->
      <slot name="cancel">
        <b-button
          v-if="!hasSlot('cancel') && showCancel"
          :disabled="loading || btnCancelDisabled"
          @click="onClickCancel"
          size="sm"
          :variant="btnCancelVariant"
          class="mr-auto"
        >
          {{ btnCancelTitle }}
        </b-button>
      </slot>

      <!-- Al actualizar -->
      <slot name="isEditing">
        <b-button
          v-if="!hasSlot('isEditing') && isEditing"
          :disabled="disabled"
          @click="onClickUpdate"
          size="sm"
          :variant="btnEditVariant"
          class="ml-auto"
          :type="btnUpdateType"
        >
          {{ loading ? 'Actualizando...' : `Actualizar ${module}` }}
        </b-button>
      </slot>

      <!-- Al crear -->
      <slot name="isCreating">
        <b-button
          v-if="!hasSlot('isCreating') && isCreating"
          :disabled="disabled"
          @click="onClickCreate"
          size="sm"
          :variant="btnCreateVariant"
          class="ml-auto"
          :type="btnCreateType"
        >
          <span v-if="btnCreateTitle !== ''">{{ btnCreateTitle }}</span>
          <span v-else>{{ loading ? 'Creando...' : `Crear ${module}` }}</span>
        </b-button>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'XFormFooterButtons',

  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },

    showCancel: {
      type: Boolean,
      required: false,
      default: true
    },

    btnCancelTitle: {
      type: String,
      required: false,
      default: 'Cancelar'
    },

    btnCancelVariant: {
      type: String,
      required: false,
      default: 'black'
    },

    btnCancelDisabled: {
      type: Boolean,
      required: false,
      default: false
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    },

    isEditing: {
      type: Boolean,
      required: false,
      default: false
    },

    btnEditVariant: {
      type: String,
      required: false,
      default: 'warning'
    },

    isCreating: {
      type: Boolean,
      required: false,
      default: false
    },

    module: {
      type: String,
      required: false,
      default: ''
    },

    btnCreateTitle: {
      type: String,
      required: false,
      default: ''
    },

    btnCreateVariant: {
      type: String,
      required: false,
      default: 'success'
    },

    btnCreateType: {
      type: String,
      required: false,
      default: 'submit'
    },

    btnUpdateType: {
      type: String,
      required: false,
      default: 'submit'
    }
  },

  methods: {
    hasSlot (name) {
      const value = Boolean(this.$slots[name])

      return value
    },

    onClickCancel () {
      this.$emit('on-cancel', true)
    },

    onClickUpdate () {
      this.$emit('on-update', true)
    },

    onClickCreate () {
      this.$emit('on-create', true)
    }
  }
}
</script>
