import service from '@/api/service'

const ENDPOINT_SUFICIENCIA = 'api/suficiencia-presupuestal'

//
// Funciones para "resetear"
const importSuficiencia = () => ({
  files: [],
  periodo_fiscal_id: null,
  result: []
})

export default {
  namespaced: true,

  state: {
    isCreatingResource: false,

    isGettingResource: false,

    isDeletingResource: false,

    isLoadingResource: false,

    //
    // Objeto para fórmulario,
    // para subir las pólizas
    importSuficiencia: importSuficiencia(),

    suficienciaList: [],

    //
    // Representa el registro de
    // suficiencia presupuestal que se esta editando actualmente
    currentSuficiencia: null,

    //
    // Representa el registro de movimiento de
    // suficiencia presupuestal que se está editando
    currentMovimiento: null,

    //
    // Flag para indicar que se ha enviado
    // una solicitud de edición del registro de suficiencia/movimiento,
    // entonces espera a obtener el item para mostrarlo en el formulario.
    waitToEditItem: false
  },

  getters: {
    getImportPoliza (state) {
      return state.importPoliza
    },

    getImportNomina (state) {
      return state.importNomina
    },

    isCreatingResource (state) {
      return state.isCreatingResource
    },

    isFormPolizaValid (state) {
      return [
        //
        // Valida que el periodo fiscal actual esté activo
        // Boolean(state.importPoliza.periodo_fiscal_id),
        //
        // Valida que el periodo fiscal esté seleccionado
        Boolean(state.importPoliza.files.length > 0)
      ].every(t => t)
    },

    polizaHasResult (state) {
      return Boolean(state.importPoliza.result && state.importPoliza.result.length >= 1)
    },

    getNominaByMonth: state => mes => {
      return state.nominasList.filter(el => el.mes === mes)
    },

    isEditingNomina (state) {
      return Boolean(state.currentNomina)
    },

    isWatingForEditItem (state) {
      return Boolean(state.waitToEditItem)
    },

    getSuficienciaByMonth: state => mes => {
      return state.suficienciaList.filter(el => el.mes === mes)
    },

    isEditingSuficiencia (state) {
      return Boolean(state.currentSuficiencia)
    },

    isEditingMovimiento (state) {
      return Boolean(state.currentMovimiento)
    },

    getMovimientosOfCurrentNomina (state) {
      return Boolean(state.currentNomina) && state.currentNomina.movimientos
        ? state.currentNomina.movimientos
        : []
    },

    getMovimientosOfCurrentSuficiencia (state) {
      return Boolean(state.currentSuficiencia) && state.currentSuficiencia.movimientos
        ? state.currentSuficiencia.movimientos
        : []
    },

    getTiposNomina (state) {
      return state.tiposNominasList
    }
  },

  mutations: {
    setIsGettingResource (state, payload) {
      state.isGettingResource = payload
    },

    setIsCreatingResource (state, payload) {
      state.isCreatingResource = payload
    },

    setIsDeletingResource (state, payload) {
      state.isDeletingResource = payload
    },

    setIsLoadingResouce (state, payload) {
      state.isLoadingResource = payload
    },

    setFilesToImportPoliza (state, payload) {
      state.importPoliza.files = payload
    },

    deleteFileFromImportPoilza (state, payload) {
      const polizas = state.importPoliza.result

      //
      // Validation
      if (polizas.length <= 0) return

      state.importPoliza.result = polizas.filter(
        e => e.content.poliza.folio !== payload.content.poliza.folio
      )
    },

    setPeriodoFiscalToImportSuficiencia (state, payload) {
      state.importSuficiencia.periodo_fiscal_id = payload
    },

    setResultToImportPoliza (state, payload) {
      state.importPoliza.result = payload
    },

    setAplicacionPolizaResponse (state, payload) {
      state.aplicacionPolizaResponse = payload
    },

    setNominasList (state, payload) {
      state.nominasList = payload
    },

    setCurrentNomina (state, payload) {
      state.currentNomina = payload
    },

    setWaitToEditItem (state, payload) {
      state.waitToEditItem = payload
    },

    setSuficienciaList (state, payload) {
      state.suficienciaList = payload
    },

    setImporteSuficiencia (state, payload) {
      const idx = state.suficienciaList.findIndex(el => el.id === payload.id)
      state.suficienciaList[idx].total = payload.importe
    },

    setCurrentSuficiencia (state, payload) {
      state.currentSuficiencia = payload
    },

    setCurrentMovimiento (state, payload) {
      state.currentMovimiento = payload
    }
  },

  actions: {
    /**
     * Crea un nuevo registro de suficiencia presupuestal.
     *
     * Es necesario para poder realizar la importación y procesarlos.
     *
     * @param {string}  payload.folio
     * @param {date}    payload.fecha
     * @param {string}  payload.concepto
     * @param {int}     payload.mes
     * @param {int}     payload.periodo_fiscal_id
     */
    async crearSuficiencia ({ dispatch }, payload) {
      let retval = {}

      try {
        const { data } = await service.post(ENDPOINT_SUFICIENCIA, payload)

        retval = data

        dispatch('listarSuficiencias', payload)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async crearMovimiento ({ dispatch }, payload) {
      let retval = {}

      try {
        const { data } = await service.post(`${ENDPOINT_SUFICIENCIA}/movimientos`, payload)

        retval = data

        dispatch('listarSuficiencias', payload)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Lista todos los registros de suficiencias presupuestales.
     *
     * Lista los registros de suficiencias presupuestales para visualizarlos en una tabla,
     * y poder hacer modificación al mismo registro, asociar una importación,
     * o eliminar.
     *
     * @param {int}     payload.periodo_fiscal_id
     */
    async listarSuficiencias ({ commit }, params) {
      let retval = {}

      try {
        const { data } = await service.get(ENDPOINT_SUFICIENCIA, { params })

        retval = data

        commit('setSuficienciaList', data.data)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async getSuficienciaById ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await service.get(`${ENDPOINT_SUFICIENCIA}/${id}`)

        retval = data

        commit('setCurrentSuficiencia', data.data)
        commit('setWaitToEditItem', false)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async getMovimientosSuficienciaById ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await service.get(`${ENDPOINT_SUFICIENCIA}/movimientos?suficiencia_id=${id}`)

        return data.data
      } catch (error) {
        retval = { error: true, ...error.response.data }
        return retval
      }
    },

    async getMovimientoSuficienciaById ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await service.get(`${ENDPOINT_SUFICIENCIA}/movimientos/${id}`)

        retval = data

        return data.data
      } catch (error) {
        retval = { error: true, ...error.response.data }
        return retval
      }
    },

    async getMovimientoById ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await service.get(`${ENDPOINT_SUFICIENCIA}/movimientos/${id}`)

        retval = data

        commit('setCurrentMovimiento', data.data)
        commit('setWaitToEditItem', false)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async updateSuficienciaById ({ commit, dispatch }, { id, payload }) {
      let retval = {}

      try {
        payload._method = 'PUT'
        const { data } = await service.put(`${ENDPOINT_SUFICIENCIA}/${id}`, payload)

        retval = data

        commit('setCurrentSuficiencia', null)
        dispatch('listarSuficiencias', {
          periodo_fiscal_id: payload.periodo_fiscal_id
        })
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async updateMovimientoById ({ commit, dispatch }, { id, payload }) {
      let retval = {}

      try {
        payload._method = 'PUT'
        const { data } = await service.put(`${ENDPOINT_SUFICIENCIA}/movimientos/${id}`, payload)

        retval = data

        commit('setCurrentMovimiento', null)
        /* dispatch('listarSuficiencias', {
          periodo_fiscal_id: payload.periodo_fiscal_id
        }) */
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async deleteMovimientoById ({ dispatch }, { id, data }) {
      let retval = {}

      try {
        const getval = await service.delete(`${ENDPOINT_SUFICIENCIA}/movimientos/${id}`, { data })

        retval = getval.data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Importa un archivo layout a un registro de nómina en específico.
     *
     * @param {file}        payload.file
     * @param {int}         payload.nomina_id
     * @param {int}         payload.periodo_fiscal_id
     * @param {Boolean}     payload.save_file
     * @param {Boolean}     payload.overwrite
     */
    async importarSuficiencias ({ dispatch }, payload) {
      let retval = {}

      try {
        const formData = new FormData()

        formData.append('file', payload.file)
        formData.append('suficiencia_id', payload.traspaso_id)
        formData.append('periodo_fiscal_id', payload.periodo_fiscal_id)
        formData.append('save_file', payload.save_file ? 1 : 0)
        formData.append('overwrite', payload.overwrite ? 1 : 0)

        const { data } = await service.post(`${ENDPOINT_SUFICIENCIA}/import`, formData)

        retval = data

        dispatch('listarSuficiencias', payload)
      } catch (error) {
        retval = { error: false, ...error.response.data }
      }

      return retval
    },

    /**
     * Elimina una nómina por su id.
     *
     * @param {int}         payload.id
     */
    async deleteSuficienciaById ({ dispatch }, { id, data }) {
      let retval = {}

      try {
        const getval = await service.delete(`${ENDPOINT_SUFICIENCIA}/${id}`, { data })

        retval = getval.data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async exportarSuficienciaPresupuestal ({ context }, id) {
      let retval = {}

      try {
        const { data } = await service({
          _method: 'GET',
          url: `${ENDPOINT_SUFICIENCIA}/export`,
          responseType: 'blob',
          params: { id }
        })

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async traspasoSuficienciaPresupuestal ({ dispatch }, id) {
      let retval = {}

      try {
        const { data } = await service.post(`${ENDPOINT_SUFICIENCIA}/traspaso`, { id })

        retval = data
      } catch (error) {
        retval = { error: false, ...error.response.data }
      }

      return retval
    }
  }
}
