<template>
  <footer class="footer mt-auto footer-light">
    <b-container fluid>
      <b-row>
        <b-col cols="12" class="text-center">&copy; Sistema Instituto Campechano</b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
export default {
  name: 'FooterView'
}
</script>
