import service from '@/api/service'
import Resource from '@/api/Resource'

const ENDPOINT = '/api/importar/analitico-egresos-unidad-administrativa'
const resource = new Resource(ENDPOINT, 'importAnaliticoEgresosUnidadAdministrativaModule')

export default {
  namespaced: true,

  state: {
    /**
     * Listado de recursos a desplegar
     */
    data: null,

    loading_data: false,

    periodo_fiscal_id: null
  },

  getters: {
    hasData: state => Boolean(state.data),

    getLoadingData: (state) => state.loading_data,

    getData: (state, getters) => getters.hasData ? state.data : []
  },

  mutations: {
    resetState (state) {
      for (const key in state) {
        state[key] = null
      }
    },

    setData (state, data) {
      state.data = data
    },

    set_periodo_fiscal_id (state, data) {
      state.periodo_fiscal_id = data
    }
  },

  actions: {
    setPeriodoFiscalId ({ commit }, periodo_fiscal_id) {
      commit('set_periodo_fiscal_id', periodo_fiscal_id)
    },

    async getData ({ state, commit }) {
      state.loading_data = true
      const { error, message, data } =
        await resource.get(`${ENDPOINT}?periodo_fiscal_id=${state.periodo_fiscal_id}`, null)
      state.loading_data = false

      if (error) return { error, message }

      commit('setData', data.data)

      return { data, message }
    },

    async deleteData ({ dispatch }, id) {
      let retval = {}

      try {
        const { data } = await resource.delete(id)

        retval = data

        dispatch('getData')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async export ({ context }, payload) {
      let retval = {}

      try {
        const { data } = await service({
          _method: 'GET',
          url: `${ENDPOINT}/${payload.id}/export`,
          responseType: 'blob',
          params: {
            id: payload.id
          }
        })

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async import ({ dispatch, state }, payload) {
      let retval = {}

      try {
        const formData = new FormData()

        formData.append('file', payload.file)
        formData.append('periodo_fiscal_id', state.periodo_fiscal_id)
        const { data } = await service.post(`${ENDPOINT}`, formData)

        retval = data

        dispatch('getData')
      } catch (error) {
        retval = { error: false, ...error.response.data }
      }

      return retval
    }
  }
}
