import Resource from '@/api/Resource'

const cuentaContable = new Resource('/api/cuenta-contable', 'cuentaContableModule')

export default {
  namespaced: true,

  state: {
    list: [],

    isGettingResource: false
  },

  getters: {
    getList: state => state.list,

    isListAvailable: state => {
      return !state.isCretingResource && !state.isGettingResource && !state.setIsDeletingResource
    }
  },

  mutations: {
    setList (state, payload) {
      state.list = payload
    },

    setIsGettingResource (state, payload) {
      state.isGettingResource = payload
    }
  },

  actions: {
    async getAll ({ commit }) {
      let retval = {}

      try {
        const { data } = await cuentaContable.all({}, 'setIsGettingResource')
        retval = data
        commit('setList', data.data)
      } catch (error) {
        retval = error.response.data
      }

      return retval
    }
  }
}
