import Resource from '@/api/Resource'
import service from '../../api/service'

const partidas = new Resource('/api/partidas', 'partidaModule')

export default {
  namespaced: true,

  state: {
    /**
     * Representa a todas las partidas en el sistema
     */
    partidas: [],

    currentPartida: null,

    isCreatingResource: false,

    isGettingResource: true,

    isDeletingResource: false
  },

  getters: {
    getPartidas: state => {
      return state.partidas ? state.partidas : []
    },

    getAvailableList: state => {
      return !state.isCretingResource && !state.isGettingResource && !state.setIsDeletingResource && state.partidas.length > 0
    },

    isEditingResource: state => Boolean(state.currentPartida),

    getCurrentEditing: state => state.currentPartida
  },

  mutations: {
    setPartidas (state, payload) {
      state.partidas = payload
    },

    setCurrentPartida (state, payload) {
      state.currentPartida = payload
    },

    setIsGettingResource (state, payload) {
      state.isGettingResource = payload
    },

    setIsCreatingResource (state, payload) {
      state.isCretingResource = payload
    },

    setIsDeletingResource (state, payload) {
      state.isDeletingResource = payload
    }
  },

  actions: {
    async getAll ({ commit }, periodo_fiscal_id) {
      const { error, data, message } = await partidas.all(
        { periodo_fiscal_id },
        'setIsGettingResource'
      )

      if (error) return { error, message }

      commit('setPartidas', data.data)

      return { data, message }
    },

    async getPartidasList ({ commit }, periodo_fiscal_id) {
      let retval = {}

      try {
        const { data } = await service.get('api/partidas?periodo_fiscal_id=' + periodo_fiscal_id)

        retval = data.data
      } catch (error) {
        console.log(error || '')

        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async getPartidasDisponibilidadList ({ commit }, periodo_fiscal_id) {
      let retval = {}

      try {
        const { data } = await service.get('api/partidas-disponibilidad?periodo_fiscal_id=' + periodo_fiscal_id)

        retval = data.data
      } catch (error) {
        console.log(error || '')

        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async getPartidasPresupuestoList ({ commit }, payload) {
      let retval = {}

      try {
        const { data } = await service.get(`api/partidas-presupuesto?periodo_fiscal_id=${payload.periodo_fiscal_id}&fuente_financiamiento_id=${payload.fuente_financiamiento_id}&proyecto_id=${payload.proyecto_id}&unidad_administrativa_id=${payload.unidad_administrativa_id}&tipo_gasto_id=${payload.tipo_gasto_id}`)

        retval = data.data
      } catch (error) {
        console.log(error || '')

        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async create (_, payload) {
      let retval = {}

      try {
        const { data } = await partidas.create(payload)

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async getToEdit ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await partidas.show(id)

        retval = data

        commit('setCurrentPartida', { ...data.data })
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async update ({ commit }, { id, payload }) {
      let retval = {}

      try {
        const { data } = await partidas.update(id, payload)

        retval = data

        commit('setCurrentPartida', null)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async delete (_, id) {
      let retval = {}

      try {
        const { data } = await partidas.delete(id)

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    }
  }
}
