import Resource from '@/api/Resource'
import service from '@/api/service'
import _ from 'lodash'

const fuenteFinanciamientoCapitulo = new Resource(
  'api/fuentes-financiamiento-capitulos',
  'fuenteFinanciamientoCapituloModule'
)

export default {
  namespaced: true,

  state: {
    list: [],

    currentResource: null,
    isCreatingResource: false,
    isGettingResource: false,
    isDeletingResource: false
  },

  getters: {
    getList: state => state.list,
    isListAvailable: state => {
      return !state.isCreatingResource && !state.isGettingResource && !state.setIsDeletingResource
    },
    isEditingResource: state => Boolean(state.currentResource),
    getCurrentEditing: state => state.currentResource
  },

  mutations: {
    setList (state, payload) {
      state.list = payload
    },

    setCurrentResource (state, payload) {
      state.currentResource = payload
    },

    setIsGettingResource (state, payload) {
      state.isGettingResource = payload
    },

    setIsCreatingResource (state, payload) {
      state.isCretingResource = payload
    },

    setIsDeletingResource (state, payload) {
      state.isDeletingResource = payload
    }
  },

  actions: {
    /**
     * Obtiene todas las fuentes de financiamiento por capítulo.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async getAll ({ commit }, payload) {
      let retval = {}

      try {
        const { data } = await fuenteFinanciamientoCapitulo.all(payload, 'setIsGettingResource')
        retval = data
        commit('setList', data.data)
      } catch (error) {
        retval = { error: true, ...error.response }
      }

      return retval
    },

    /**
     * Crea un recurso.
     *
     * @param {Object} payload.name           Nombre del recurso.
     * @param {Object} payload.description    Nombre del recurso.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async create ({ dispatch }, payload) {
      let retval = {}

      try {
        const { data } = await fuenteFinanciamientoCapitulo.create(payload, 'setIsCreatingResource')
        retval = data
        dispatch('getAll')
      } catch (error) {
        retval = error.response.data
      }

      return retval
    },

    /**
     * Obtiene un recurso en específico.
     *
     * @param {int} id Id del recurso a obtener.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async getById ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await fuenteFinanciamientoCapitulo.show(id)
        retval = data
        commit('setCurrentResource', data.data)
      } catch (error) {
        retval = error.response.data
      }

      return retval
    },

    async getToEdit ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await fuenteFinanciamientoCapitulo.show(id)

        retval = data

        commit('setCurrentResource', { ...data.data })
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     *  Actualiza un recurso.
     *
     * @param {int}     id                    Id del recurso a actualizar.
     * @param {Object}  params.name          Nombre del recurso a actualizar.
     * @param {Object}  params.description   Descripción del recurso a actualizar.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async updateById ({ commit }, payload) {
      let retval = {}

      try {
        const { data } = await fuenteFinanciamientoCapitulo.update(payload.id, payload)
        retval = data
        commit('setCurrentResource', null)
        // await dispatch('getAll')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Eliminar un recurso dado su id.
     *
     * @param {int} id Id del recurso a eliminar
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async deleteById ({ dispatch }, id) {
      let retval = {}

      try {
        const { data } = await fuenteFinanciamientoCapitulo.delete(id, 'setIsDeletingResource')
        retval = data
        await dispatch('getAll')
      } catch (error) {
        retval = error.response.data
      }

      return retval
    },

    async getDefault ({ dispatch }, value) {
      let retval = {}

      try {
        const { data } = await service.get(`api/panel-de-control/${value}`)

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async getByName ({ dispatch }, { key, value }) {
      let retval = {}

      try {
        const { data } = await dispatch('getAll')

        retval.data = _.find(data, { [key]: value })
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    }
  }
}
