<template>
  <div>
    <div class="card card-header-actions mb-4">
      <div class="card-header" v-if="!isForDashboard">
        <div v-if="$can('listar bitacoras de usuarios')">
          <b-form-checkbox
            v-model="checked"
            @change="changeHistoryType"
            switch
            inline
            checked
          >
            {{ title }}
          </b-form-checkbox>
        </div>
        <div v-else>{{ title }}</div>
      </div>
      <div class="card-header" v-else>
        Actividad reciente
      </div>
      <div class="card-body">
        <div class="timeline timeline-xs" v-if="getAvailableLogs">
          <div
            class="timeline-item"
            v-for="log in getActivityLogs"
            :key="log.id"
          >
              <div class="timeline-item-marker">
                  <div class="timeline-item-marker-text">{{ $formatForHumans(log.fecha) }}</div>
                  <div :class="getIndicatorColor(log.color)"></div>
              </div>
              <div class="timeline-item-content">
                {{ log.mensaje }}{{ log.afectado ? ':' : '' }}
                <span class="font-weight-bold text-dark">{{ log.afectado }}</span>
                <a v-if="log.ejecutado_por">
                  | Ejecutado por <span class="font-weight-bold text-dark">{{ log.ejecutado_por }}</span>
                </a>
                <div class="dropdown" v-if="log.propiedades.length != 0">
                    <button
                      class="btn btn-xs btn-primary dropdown-toggle"
                      id="dropdownMenuButton"
                      type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Cambios</button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <div class="dropdown-item"
                          v-for="(propiedad, key) in log.propiedades"
                          :key="propiedad.nombre">
                          <strong>{{ key.toUpperCase() }}</strong>: {{ propiedad }}
                        </div>
                    </div>
                </div>
              </div>
          </div>
        </div>
        <loading v-else></loading>
      </div>
      <div class="card-footer" v-if="!isForDashboard">
      <paginator
          aria-controls="historyTable"
          :disabled="!getAvailableLogs"
          :show-path="showPath"
          :resource="$store.state.historyModule.activityLogs.data">
        </paginator>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'History',

  async created () {
    await this.changeHistoryType(this.canListAll)
  },

  props: {
    isForDashboard: {
      type: Boolean,
      value: false
    }
  },

  data () {
    return {
      canListAll: this.$can('listar bitacoras de usuarios'),
      checked: true,
      fields: [
        { key: 'accion', label: 'Acción' },
        { key: 'accion_ejecutada_por', label: 'Ejecutada por' },
        { key: 'fecha', fecha: 'Fecha' },
        { key: 'user_agent', fecha: 'user_agent' }
      ]
    }
  },

  computed: {
    ...mapGetters('historyModule', [
      'getActivityLogs', 'getAvailableLogs', 'allItems'
    ]),

    showPath () {
      return this.checked && this.canListAll ? 'historyModule/getActivityLogs' : 'historyModule/getActivityLogsFromUser'
    },

    title () {
      return this.checked && this.canListAll ? 'Historial general' : 'Historial del usuario'
    }
  },

  methods: {
    async changeHistoryType () {
      await this.$store.dispatch(`${this.showPath}`)
    },

    getIndicatorColor (color) {
      return `timeline-item-marker-indicator bg-${color}`
    },

    getEjecutadoPor (ejecutadoPor) {
      return ejecutadoPor ? `| Ejecutado por ${ejecutadoPor}` : ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .timeline-item-marker-text {
    width: 6rem !important;
  }
</style>
