import service from '@/api/service'

export default {
  namespaced: true,

  state: {},

  getters: {
    /**
     * Indica si el usuario está autenticado o no, en base
     * si se ha obtenido información del usuario actual.
     *
     * @param {Object} state Objeto state del módulo actual.
     * @param {Object} getters Objeto getters del módulo actual
     * @param {Object} rootState Objeto state de la raíz de Vuex.
     *
     * @return {boolean} Indica si el usuario está autenticado o no.
     */
    isAuthenticated: (state, getters, rootState) => Boolean(rootState.userModule.authUser)
  },

  mutations: {},

  actions: {
    /**
     *
     * Para autenticar el SPA, se debe de hacer primero una petición
     * al endpoint "/sanctum/csrf-cookie" para initializar la protección CSRF
     *
     * @link https://laravel.com/docs/8.x/sanctum#csrf-protection
     *
     * @param {string}  user.email      Email del usuario a autenticar.
     * @param {string}  user.password   Contraseña del usuario a autenticar.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async login ({ dispatch }, user) {
      let retval = {}

      try {
        await service.get('/sanctum/csrf-cookie')
        const { data } = await service.post('/api/login', user)
        retval = data

        await dispatch('userModule/getAuthUser', {}, { root: true })
      } catch (error) {
        if (error.response && error.response.data) return { error: true, ...error.response.data }

        retval = { error: true, message: error }
      }

      return retval
    },

    async loginWithGoogle ({ dispatch }, { provider, response }) {
      let retval = {}

      try {
        const { data } = await service.post(`/api/auth/${provider}/callback`, response)
        retval = data

        await dispatch('userModule/getAuthUser', {}, { root: true })
      } catch (error) {
        if (error.response && error.response.data) return { error: true, ...error.response.data }

        retval = { error: true, message: error }
      }

      return retval
    },

    /**
     * Método para cerrar la sesión del usuario actual.
     *
     * Realiza una petición al servidor para invalidar la sesión
     * actual, si la respuesta es satisfactoria limpia vuex.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async logout ({ commit }) {
      let retval = {}

      try {
        const { data } = await service.post('/api/logout')
        retval = data

        commit('userModule/setAuthUser', null, { root: true })
        commit('userModule/resetState', null, { root: true })
        commit('historyModule/resetState', null, { root: true })
      } catch (error) {
        retval = { error: true, ...error }
      }

      return retval
    },

    /**
     * Hace una petición para la recuperación de contraseña.
     *
     * Si el correo es correcto entonces manda un link de recuperación,
     * en donde se genera un token el cual es validado al cambiar la contraseña.
     *
     * @param {string} email Email del usuario a solicitar la recuperación de contraseña.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async recoverPassword ({ context }, email) {
      let retval = {}

      try {
        const { data } = await service.post('/api/forgot-password', { email })
        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Método para cambiar la contraseña del usuario por medio de un token.
     *
     * Esta función es usada para cambiar la contraseña después de enviar
     * al usuario un email de recuperación de contraseña.
     *
     * La petición solicita los siguientes parámetros requeridos.
     *
     * @param {string} payload.token    Token generado por Laravel.
     * @param {string} payload.email    Email del usuario a cambiar su contraseña.
     * @param {string} payload.password Nueva contraseña del usuario.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async resetPassword ({ commit }, payload) {
      let retval = {}

      try {
        await service.get('sanctum/csrf-cookie')
        const { data } = await service.post('/api/reset-password', payload)
        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    }
  }
}
