import service from '@/api/service'
import Resource from '@/api/Resource'

//
// Ruta para la importación de las pólizas,
// la importación de las pólizas pueden ser múltiples.
const IMPORTAR_URL = 'api/importar-poliza-xaml'

const importPolizas = new Resource(IMPORTAR_URL, 'polizasRHModule')

const ENDPOINT_POLIZAS = 'api/polizas'

//
// Funciones para "resetear"
const importPoliza = () => ({
  files: [],
  periodo_fiscal_id: null,
  result: []
})

export default {
  namespaced: true,

  state: {
    isCreatingResource: false,

    isGettingResource: false,

    isDeletingResource: false,

    isLoadingResource: false,

    //
    // Objeto para fórmulario,
    // para subir las pólizas
    importPoliza: importPoliza(),

    aplicacionPolizaResponse: [],

    polizasList: [],

    currentPoliza: null,

    //
    // Flag para indicar que se ha enviado
    // una solicitud de edición del registro de nómina,
    // entonces espera a obtener el item para mostrarlo en el formulario.
    waitToEditItem: false,
    /**
     * Término ingresado para buscar poliza
     */
    query: null
  },

  getters: {
    getImportPoliza (state) {
      return state.importPoliza
    },

    isCreatingResource (state) {
      return state.isCreatingResource
    },

    isFormPolizaValid (state) {
      return [
        //
        // Valida que el periodo fiscal actual esté activo
        // Boolean(state.importPoliza.periodo_fiscal_id),
        //
        // Valida que el periodo fiscal esté seleccionado
        Boolean(state.importPoliza.files.length > 0)
      ].every(t => t)
    },

    polizaHasResult (state) {
      return Boolean(state.importPoliza.result && state.importPoliza.result.length >= 1)
    },

    isWatingForEditItem (state) {
      return Boolean(state.waitToEditItem)
    },

    getMovimientosOfCurrentPoliza (state) {
      return Boolean(state.currentPoliza) && state.currentPoliza.movimientos
        ? state.currentPoliza.movimientos
        : []
    },
    hasPolizas: state => Boolean(state.polizasList),
    getPolizas: (state, getters) =>
      getters.hasPolizas ? state.polizasList : [],
    /**
     * 'isSearchingMode'.
     *
     * Indica si hay un texto de búsqueda para filtrado.
     */
    isSearchingMode: state => Boolean(state.query)
  },

  mutations: {
    setIsGettingResource (state, payload) {
      state.isGettingResource = payload
    },

    setIsCreatingResource (state, payload) {
      state.isCreatingResource = payload
    },

    setIsDeletingResource (state, payload) {
      state.isDeletingResource = payload
    },

    setIsLoadingResouce (state, payload) {
      state.isLoadingResource = payload
    },

    setFilesToImportPoliza (state, payload) {
      state.importPoliza.files = payload
    },

    deleteFileFromImportPoilza (state, payload) {
      const polizas = state.importPoliza.result

      //
      // Validation
      if (polizas.length <= 0) return

      state.importPoliza.result = polizas.filter(
        e => e.content.poliza.folio !== payload.content.poliza.folio
      )
    },

    setPeriodoFiscalToImportPoliza (state, payload) {
      state.importPoliza.periodo_fiscal_id = payload
    },

    setResultToImportPoliza (state, payload) {
      state.importPoliza.result = payload
    },

    resetImportPoliza (state) {
      state.importPoliza = importPoliza()
    },

    setAplicacionPolizaResponse (state, payload) {
      state.aplicacionPolizaResponse = payload
    },

    setPolizasList (state, payload) {
      state.polizasList = payload
    },

    setCurrentPoliza (state, payload) {
      state.currentPoliza = payload
    },

    setWaitToEditItem (state, payload) {
      state.waitToEditItem = payload
    },

    setQuery (state, payload) {
      state.query = payload
    }
  },

  actions: {
    /**
     * Importa una o varias pólizas y almacena el resultado
     * en una propiedad del objeto del cual se está trabajando
     * sus propiedades.
     *
     */
    async importarPolizaXaml ({ commit }, payload) {
      let retval = {}

      try {
        const formData = new FormData()

        for (let i = 0; i < payload.files.length; i++) {
          formData.append('files[]', payload.files[i])
        }

        formData.append('periodo_fiscal_id', payload.periodo_fiscal_id)

        const { data } = await importPolizas.create(formData, 'setIsCreatingResource')

        commit('setResultToImportPoliza', data.data)

        //
        // Borra si ha habido alertas anteriores a un proceso
        commit('setAplicacionPolizaResponse', [])

        retval = data
      } catch (error) {
        retval = { error: false, ...error.response.data }
      } finally {
        commit('setIsCreatingResource', false)
      }

      //
      // Elimina los archivos que se han almacenado
      commit('setFilesToImportPoliza', [])

      return retval
    },

    /**
     * aplicarPolizas.
     *
     * Se ejecuta cuando el usuario ha decidido aplicar la previsualización de las pólizas,
     * previsamente subidas.
     */
    async aplicarPolizas ({ state, commit }, payload) {
      let retval = {}

      try {
        //
        // Adiciona los datos
        payload.periodo_fiscal_id = state.importPoliza.periodo_fiscal_id
        payload.polizas = []
        payload._method = 'PUT'

        for (let i = 0; i < state.importPoliza.result.length; i++) {
          payload.polizas.push(state.importPoliza.result[i])
        }

        const { data } = await service.put(IMPORTAR_URL, payload)

        commit('setFilesToImportPoliza', [])
        commit('setResultToImportPoliza', [])
        commit('setPeriodoFiscalToImportPoliza', null)

        //
        // Guarda la respuesta del proceso de aplicación de las pólizas
        // para mostrarlo al usuario
        commit('setAplicacionPolizaResponse', data.data)

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * update.
     *
     * Se ejecuta para actualizar el status de una póliza
     */
    async update ({ commit }, payload) {
      let retval = {}

      try {
        const { data } = await service.put(`${ENDPOINT_POLIZAS}/${payload.id}`, { status: payload.status })

        retval = data

        commit('setCurrentPoliza', data.data)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * listar.
     *
     * Se ejecuta para obtener el listado de pólizas
     */
    async listar ({ state, getters, commit, dispatch }, params) {
      let retval = {}
      const page = null

      // const { data } = await service.get(ENDPOINT_POLIZAS, { params })
      if (getters.isSearchingMode) return dispatch('search', { search: state.query, page: page })

      const { error, message, data } =
        params.page !== null
          ? await importPolizas.get(`${ENDPOINT_POLIZAS}?page=${page}`, params, 'setIsGettingResource')
          : await importPolizas.get(ENDPOINT_POLIZAS, params, 'setIsGettingResource')

      if (error) return { error, message }

      retval = data

      commit('setPolizasList', data.data.polizas)

      return retval
    },

    async search ({ commit }, params) {
      const { error, message, data } = await importPolizas.get(
        'api/polizas/search',
        params,
        'setIsGettingResource'
      )

      if (error) return { error, message }

      commit('setPolizasList', data.data.data)

      return data
    },

    /**
     * getById.
     *
     * Se ejecuta para obtener una póliza en específico
     */
    async getById ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await service.get(`${ENDPOINT_POLIZAS}/${id}`)

        retval = data

        commit('setCurrentPoliza', data.data)
        commit('setWaitToEditItem', false)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * listar.
     *
     * Se ejecuta para obtener el listado de pólizas
     */
    async movimientos ({ commit }, params) {
      let retval = {}

      try {
        const { data } = await service.get(`${ENDPOINT_POLIZAS}/movimientos?poliza_id=${params.poliza_id}`)

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * descargar.
     *
     * Se ejecuta para descargar el archivo que se subió para la importación
     */
    async descargar ({ context }, id) {
      let retval = {}

      try {
        const { data } = await service({
          _method: 'GET',
          url: `${ENDPOINT_POLIZAS}/${id}/descargar`,
          responseType: 'blob'
        })

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * exportar.
     *
     * Exportación a Excel del listado de pólizas que se visualiza en frontend
     */
    async exportar ({ context }, ids) {
      let retval = {}

      try {
        const formData = new FormData()

        formData.append('polizas_ids', ids.join(','))

        const { data } = await service.post(`${ENDPOINT_POLIZAS}/exportar`, formData,
          { responseType: 'blob' })

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    }
  }
}
