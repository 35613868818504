// import service from '@/api/service'
import Resource from '@/api/Resource'

const resource = new Resource('/api/budget-keys', 'clavesPresupuestalesModule')

export default {
  namespaced: true,

  state: {
    list: [],

    isCretingResource: false,

    isGettingResource: false,

    isDeletingResource: false
  },

  getters: {
    hastList: ({ list }) => list.data && list.data.data && list.data.data.length > 0,

    getList: ({ list }, getters) => (getters.hastList ? list.data.data : []),

    isListAvailable: state => {
      return !state.isCretingResource && !state.isGettingResource && !state.setIsDeletingResource
    }
  },

  mutations: {
    setList (state, payload) {
      state.list = payload
    },

    setIsGettingResource (state, payload) {
      state.isGettingResource = payload
    },

    setIsCreatingResource (state, payload) {
      state.isCretingResource = payload
    },

    setIsDeletingResource (state, payload) {
      state.isDeletingResource = payload
    }
  },

  actions: {
    /**
     * Hace una petición para obtener todas las claves presupuestales.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async getAll ({ state, getters, commit, dispatch }, params, page = null) {
      let retval = {}

      try {
        const { data } = await resource.all(params)

        retval = data

        commit('setList', data)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async getById ({ state, commit }) {},

    /**
     * Petición asíncrona para crear una nueva clave presupuestal.
     *
     * @param {string} payload.email Email del recurso
     * @param {string} payload.password Contraseña del recurso
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async create ({ dispatch }, payload) {
      let retval = {}

      try {
        const { data } = await resource.create(payload)

        retval = data

        dispatch('getAll')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Actualiza específicamente una clave presupuestal.
     *
     * @param {int} id Id del recurso a actualizar.
     * @param {string} payload.nombre Nombre de la persona.
     * @param {string} payload.email Email del recurso.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async updateById ({ context }, { id, payload }) {
      let retval = {}

      try {
        const { data } = await resource.update(id, payload)

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Elimina un recurso en específico.
     *
     * @param {int} id Id del recurso a eliminar.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async deleteById ({ dispatch }, id) {
      let retval = {}

      try {
        const { data } = await resource.delete(id)

        retval = data

        dispatch('getAll')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    }
  }
}
