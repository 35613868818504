import Resource from '@/api/Resource'

const momentos = new Resource('/api/momentos-contables', 'momentoModule')

export default {
  namespaced: true,

  state: {
    /**
     * Momentos contables
     */
    momentos: [],

    currentMomento: null,

    isCreatingResource: false,

    isGettingResource: false,

    isDeletingResource: false
  },

  getters: {
    getMomentos: state => {
      return state.momentos ? state.momentos : []
    },

    getAvailableList: state => {
      return !state.isCretingResource && !state.isGettingResource && !state.setIsDeletingResource
    },

    isEditingResource: state => Boolean(state.currentPartida),

    getCurrentEditing: state => state.currentPartida
  },

  mutations: {
    setMomentos (state, payload) {
      state.momentos = payload
    },

    setCurrentMomento (state, payload) {
      state.currentMomento = payload
    },

    setIsGettingResource (state, payload) {
      state.isGettingResource = payload
    },

    setIsCreatingResource (state, payload) {
      state.isCretingResource = payload
    },

    setIsDeletingResource (state, payload) {
      state.isDeletingResource = payload
    }
  },

  actions: {
    async getAll ({ commit }) {
      const { error, data, message } = await momentos.all(
        {},
        'setIsGettingResource'
      )

      if (error) return { error, message }

      commit('setMomentos', data.data)

      return { data, message }
    }
  }
}
