<template>
  <div id="layoutSidenav_nav" class="no-selection">
    <nav class="sidenav shadow-right sidenav-light">
      <div class="sidenav-menu">
        <div class="nav accordion" id="accordionSidenav">
          <!-- Cuenta -->
          <div class="sidenav-menu-heading">Cuenta</div>

          <a
            class="nav-link bg-danger text-white my-cursor"
            v-if="$store.getters['userModule/isViewingAsAnotherUser']"
            @click="exitOfViewAs"
          >
            <div class="nav-link-icon text-white"><i data-feather="corner-down-left"></i></div>
            Salir de "ver como"
          </a>

          <router-link :to="{ name: 'Dashboard' }" class="nav-link">
            <div class="nav-link-icon">
              <i data-feather="home"></i>
            </div>
            Inicio
          </router-link>

          <!-- Notificaciones -->
          <router-link :to="{ name: 'Notifications' }" class="nav-link">
            <div class="nav-link-icon">
              <i data-feather="bell"></i>
            </div>
            Notificaciones
            <span
              v-show="getNotificationsCount > 0"
              class="badge badge-warning-soft text-warning ml-auto"
            >
              {{ getNotificationsCount }} Nuevas
            </span>
          </router-link>
          <!-- End Cuenta -->

          <!-- Administración-->
          <div class="sidenav-menu-heading">Administración</div>

          <!-- Sidenav Accordion (Usuarios y permisos)-->
          <a
            class="nav-link my-cursor"
            :class="activeAccordeon === 'usersAndPermissions' ? '' : 'collapsed'"
            data-toggle="collapse"
            data-target="#collapseDashboards"
            :aria-expanded="activeAccordeon === 'usersAndPermissions' ? true : false"
            aria-controls="collapseDashboards"
          >
            <div class="nav-link-icon"><i data-feather="users"></i></div>
            Usuarios y permisos
            <div class="sidenav-collapse-arrow">
              <i class="fas fa-angle-down"></i>
            </div>
          </a>
          <div
            :class="activeAccordeon === 'usersAndPermissions' ? 'collapse show' : 'collapse'"
            id="collapseDashboards"
            data-parent="#accordionSidenav"
          >
            <nav class="sidenav-menu-nested nav accordion" id="accordionSidenavPages">
              <sidebar-link
                :requiresAuthorizations="['acceso usuarios']"
                :to="{ name: 'Users', params: { tabActive: 'users' } }"
              >
                Usuarios
              </sidebar-link>

              <sidebar-link
                :requiresAuthorizations="['acceso roles']"
                :to="{ name: 'Roles', params: { tabActive: 'roles' } }"
              >
                Roles
              </sidebar-link>

              <sidebar-link
                :requiresAuthorizations="['acceso permisos']"
                :to="{ name: 'Permissions', params: { tabActive: 'permissions' } }"
              >
                Permisos
              </sidebar-link>
            </nav>
          </div>

          <!-- Nested Sidenav Accordion (Catálogos -> Períodos Fiscales)-->
          <sidebar-link
            :requiresAuthorizations="['acceso periodos fiscales']"
            :to="{ name: 'PeriodosFiscales' }"
            :show-permission-help="false"
          >
            <div class="nav-link-icon">
              <i data-feather="calendar"></i>
            </div>
            Períodos Fiscales
          </sidebar-link>

          <!-- End Administración -->

          <!-- Catálogos -->
          <div class="sidenav-menu-heading">Catálogos</div>

          <!-- Catálogos Institucionales -->
          <a
            class="nav-link collapsed my-cursor"
            :class="activeAccordeon === 'institucionales' ? '' : 'collapsed'"
            data-toggle="collapse"
            data-target="#collapseCatalogosInstitucionalesPages"
            :aria-expanded="activeAccordeon === 'institucionales' ? true : false"
            aria-controls="collapseCatalogosInstitucionalesPages"
          >
            <div class="nav-link-icon"><i data-feather="grid"></i></div>
            Institucionales
            <div class="sidenav-collapse-arrow">
              <i class="fas fa-angle-down"></i>
            </div>
          </a>
          <div
            :class="activeAccordeon === 'institucionales' ? 'collapse show' : 'collapse'"
            id="collapseCatalogosInstitucionalesPages"
            data-parent="#accordionSidenav"
          >
            <nav
              class="sidenav-menu-nested nav accordion"
              id="accordionSidenavPagesMenuCatalogosInstitucionales"
            >
              <!-- Nested Sidenav Accordion (Catálogos -> Unidades Administrativas)-->
              <sidebar-link
                :requiresAuthorizations="['acceso unidades administrativas']"
                :to="{ name: 'AdministrativeUnits' }"
              >
                Unidades administrativas
              </sidebar-link>

              <!-- Nested Sidenav Accordion (Catálogos -> Fuentes de Financiamiento)-->
              <sidebar-link
                :requiresAuthorizations="['acceso fuentes financiamiento']"
                :to="{ name: 'FuentesFinanciamiento' }"
              >
                Fuentes de Financiamiento
              </sidebar-link>

              <!-- Nested Sidenav Accordion (Catálogos -> Proyectos)-->
              <sidebar-link
                :requiresAuthorizations="['acceso proyectos']"
                :to="{ name: 'Proyectos', params: { tabActive: 'proyectos' } }"
              >
                Proyectos
              </sidebar-link>

              <!-- Nested Sidenav Accordion (Catálogos -> Partidas)-->
              <sidebar-link
                :requiresAuthorizations="['acceso partidas']"
                :to="{ name: 'Partidas', params: { tabActive: 'partidas' } }"
              >
                Partidas
              </sidebar-link>

              <!-- Nested Sidenav Accordion (Catálogos -> Tipos de Gasto)-->
              <sidebar-link
                :requiresAuthorizations="['acceso tipos de gasto']"
                :to="{ name: 'TiposGasto' }"
              >
                Tipos de Gasto
              </sidebar-link>
            </nav>
          </div>

          <!-- Catálogos Auxiliares-->
          <a
            class="nav-link collapsed my-cursor"
            :class="activeAccordeon === 'catalogos' ? '' : 'collapsed'"
            data-toggle="collapse"
            data-target="#collapsePages"
            :aria-expanded="activeAccordeon === 'catalogos' ? true : false"
            aria-controls="collapsePages"
          >
            <div class="nav-link-icon"><i data-feather="grid"></i></div>
            Auxiliares
            <div class="sidenav-collapse-arrow">
              <i class="fas fa-angle-down"></i>
            </div>
          </a>
          <div
            :class="activeAccordeon === 'catalogos' ? 'collapse show' : 'collapse'"
            id="collapsePages"
            data-parent="#accordionSidenav"
          >
            <nav class="sidenav-menu-nested nav accordion" id="accordionSidenavPagesMenu">
              <!-- Nested Sidenav Accordion (Catálogos -> Matriz de unidades administrativas)-->
              <sidebar-link
                :requiresAuthorizations="['obtener matriz unidad administrativa prestacion']"
                :to="{
                  name: 'MatrizUnidadesAdministrativas',
                  params: { tabActive: 'matrizUnidadesAdministrativas' }
                }"
              >
                Matriz de unidades administrativas
              </sidebar-link>

              <!-- Nested Sidenav Accordion (Catálogos -> Prestaciones)-->
              <sidebar-link
                :requiresAuthorizations="['acceso prestaciones']"
                :to="{ name: 'Prestaciones' }"
              >
                Prestaciones
              </sidebar-link>

              <!-- Nested Sidenav Accordion (Catálogos -> Días feriados)-->
              <!-- Fix: ruta y permisos -->
              <!-- <sidebar-link
                :requiresAuthorizations="[]"
                :to="{ name: 'Dashboard' }"
              >
                Días feriados
              </sidebar-link> -->

              <!-- <div class="collapse" id="pagesCollapseAuth" data-parent="#accordionSidenavPagesMenu">
                <nav class="sidenav-menu-nested nav accordion" id="accordionSidenavPagesAuth">

                  Nested Sidenav Accordion (Catálogos -> Días feriados -> )
                  <a
                    class="nav-link collapsed"
                    href="javascript:void(0);"
                    data-toggle="collapse"
                    data-target="#pagesCollapseAuthBasic"
                    aria-expanded="false"
                    aria-controls="pagesCollapseAuthBasic"
                  >
                    Basic
                    <div class="sidenav-collapse-arrow">
                      <i class="fas fa-angle-down"></i>
                    </div>
                  </a>
                  <div
                    class="collapse"
                    id="pagesCollapseAuthBasic"
                    data-parent="#accordionSidenavPagesAuth"
                  >
                    <nav class="sidenav-menu-nested nav">
                      <a class="nav-link" href="auth-login-basic.html">
                        Login
                      </a>
                      <a class="nav-link" href="auth-register-basic.html">
                        Register
                      </a>
                      <a class="nav-link" href="auth-password-basic.html">
                        Forgot Password
                      </a>
                    </nav>
                  </div>

                  Nested Sidenav Accordion (Catálogos -> Días feriados -> )
                  <a
                    class="nav-link collapsed"
                    href="javascript:void(0);"
                    data-toggle="collapse"
                    data-target="#pagesCollapseAuthSocial"
                    aria-expanded="false"
                    aria-controls="pagesCollapseAuthSocial"
                  >
                    Social
                    <div class="sidenav-collapse-arrow">
                      <i class="fas fa-angle-down"></i>
                    </div>
                  </a>
                  <div
                    class="collapse"
                    id="pagesCollapseAuthSocial"
                    data-parent="#accordionSidenavPagesAuth"
                  >
                    <nav class="sidenav-menu-nested nav">
                      <a class="nav-link" href="auth-login-social.html">
                        Login
                      </a>
                      <a class="nav-link" href="auth-register-social.html">
                        Register
                      </a>
                      <a class="nav-link" href="auth-password-social.html">
                        Forgot Password
                      </a>
                    </nav>
                  </div>
                </nav>
              </div> -->

              <!-- Nested Sidenav Accordion (Catálogos -> submenú)-->
              <!-- <a
                class="nav-link collapsed"
                href="javascript:void(0);"
                data-toggle="collapse"
                data-target="#pagesCollapseError"
                aria-expanded="false"
                aria-controls="pagesCollapseError"
              >
                Mi submenú
                <div class="sidenav-collapse-arrow">
                  <i class="fas fa-angle-down"></i>
                </div>
              </a> -->
              <!-- Descomentar para activar submenu -->
              <!-- <div
                class="collapse"
                id="pagesCollapseError"
                data-parent="#accordionSidenavPagesMenu"
              >
                <nav class="sidenav-menu-nested nav">
                  <a class="nav-link" href="#">400 Error</a>
                  <a class="nav-link" href="#">401 Error</a>
                  <a class="nav-link" href="#">403 Error</a>
                  <a class="nav-link" href="#">
                    404 Error 1
                  </a>
                  <a class="nav-link" href="#">
                    404 Error 2
                  </a>
                  <a class="nav-link" href="#">500 Error</a>
                  <a class="nav-link" href="#">503 Error</a>
                  <a class="nav-link" href="#">504 Error</a>
                </nav>
              </div> -->
              <!-- Nested Sidenav Accordion (Pages -> Knowledge Base)-->

              <!-- <a class="nav-link" href="#">Importar/Exportar catálogos</a> -->
            </nav>
          </div>

          <!-- Sidenav Accordion (Movimientos)-->
          <!-- <a
            class="nav-link collapsed my-cursor"
            data-toggle="collapse"
            data-target="#collapseUtilities"
            aria-expanded="false"
            aria-controls="collapseUtilities"
          >
            <div class="nav-link-icon"><i data-feather="dollar-sign"></i></div>
            Movimientos
            <div class="sidenav-collapse-arrow">
              <i class="fas fa-angle-down"></i>
            </div>
          </a> -->
          <!-- <div class="collapse" id="collapseUtilities" data-parent="#accordionSidenav">
            <nav class="sidenav-menu-nested nav">
              <a class="nav-link" href="#">Egresos</a>
              <a class="nav-link" href="#">Traspasos</a>
            </nav>
          </div> -->

          <!-- Presupuestos -->
          <div class="sidenav-menu-heading">Presupuestos</div>

          <sidebar-link
            :requiresAuthorizations="['acceso claves presupuestales']"
            :to="{ name: 'ClavesPresupuestales' }"
            :show-permission-help="false"
          >
            <div class="nav-link-icon">
              <i data-feather="key"></i>
            </div>
            Claves presupuestales
          </sidebar-link>

          <!-- Planeación -->
          <a
            class="nav-link collapsed my-cursor"
            :class="activeAccordeon === 'layouts' ? '' : 'collapsed'"
            data-toggle="collapse"
            data-target="#collapseFlows"
            :aria-expanded="activeAccordeon === 'layouts' ? true : false"
            aria-controls="collapseFlows"
          >
            <div class="nav-link-icon"><i data-feather="layers"></i></div>
            Planeación
            <div class="sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
          </a>
          <div
            :class="activeAccordeon === 'layouts' ? 'collapse show' : 'collapse'"
            id="collapseFlows"
            data-parent="#accordionSidenav"
          >
            <nav class="sidenav-menu-nested nav" id="accordionSidenavSub">
              <a
                class="nav-link collapsed my-cursor"
                :class="activeAccordeon === 'submenu' ? '' : 'collapsed'"
                data-toggle="collapse"
                data-target="#collapseFlowsSubMenu"
                :aria-expanded="activeAccordeon === 'submenu' ? true : false"
                aria-controls="collapseFlowsSubMenu"
              >
                <div class="nav-link-icon"><i data-feather="layers"></i></div>
                Recurso Estatal Capítulo 1000
                <div class="sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
              </a>
              <div
                :class="activeAccordeon === 'submenu' ? 'collapse show' : 'collapse'"
                id="collapseFlowsSubMenu"
                data-parent="#accordionSidenavSub"
              >
                <nav class="sidenav-menu-nested nav" id="accordionSidenavSubSub">
                  <sidebar-link
                    :requiresAuthorizations="['listar presupuestos']"
                    :to="{ name: 'RecursoEstatalPlaneaciones' }"
                  >
                    Listado
                  </sidebar-link>
                  <!-- <sidebar-link
                    :requiresAuthorizations="['importar salarios']"
                    :to="{ name: 'RecursoEstatalImportacion' }"
                  >
                    Importar
                  </sidebar-link>
                  <sidebar-link
                    :requiresAuthorizations="['generar proyeccion']"
                    :to="{ name: 'RecursoEstatalProyeccion' }"
                  >
                    Proyección
                  </sidebar-link>

                  <sidebar-link
                    :requiresAuthorizations="['consultar analisis presupuesto']"
                    :to="{ name: 'RecursoEstatalAnalisis' }"
                  >
                    Análisis
                  </sidebar-link> -->

                </nav>
              </div>

              <a
                class="nav-link collapsed my-cursor"
                :class="activeAccordeon === 'submenu' ? '' : 'collapsed'"
                data-toggle="collapse"
                data-target="#collapseFlowsSubMenu2"
                :aria-expanded="activeAccordeon === 'submenu' ? true : false"
                aria-controls="collapseFlowsSubMenu2"
              >
                <div class="nav-link-icon"><i data-feather="layers"></i></div>
                Recurso Federal e Ingresos Propios
                <div class="sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
              </a>
              <div
                :class="activeAccordeon === 'submenu' ? 'collapse show' : 'collapse'"
                id="collapseFlowsSubMenu2"
                data-parent="#accordionSidenavSub"
              >
                <nav class="sidenav-menu-nested nav" id="accordionSidenavSubSub2">
                  <sidebar-link
                    :requiresAuthorizations="['importar presupuesto federal']"
                    :to="{ name: 'PresupuestoFederalImport', params: { tabIndex: 0 } }"
                  >
                    Importar
                  </sidebar-link>

                  <sidebar-link
                    :requiresAuthorizations="['consultar presupuesto federal']"
                    :to="{ name: 'PresupuestoFederalList' }">
                    Visualizar
                  </sidebar-link>
                </nav>
              </div>

              <a
                class="nav-link collapsed my-cursor"
                :class="activeAccordeon === 'submenu' ? '' : 'collapsed'"
                data-toggle="collapse"
                data-target="#collapseFlowsSubMenu3"
                :aria-expanded="activeAccordeon === 'submenu' ? true : false"
                aria-controls="collapseFlowsSubMenu3"
              >
                <div class="nav-link-icon"><i data-feather="layers"></i></div>
                Ampliaciones y Reducciones
                <div class="sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
              </a>
              <div
                :class="activeAccordeon === 'submenu' ? 'collapse show' : 'collapse'"
                id="collapseFlowsSubMenu3"
                data-parent="#accordionSidenavSub"
              >
                <nav class="sidenav-menu-nested nav" id="accordionSidenavSubSub3">
                  <sidebar-link
                    :requiresAuthorizations="['importar ampliacion y reduccion']"
                    :to="{ name: 'AmpliacionReduccionImport', params: { tabIndex: 0 } }"
                  >
                    Importar
                  </sidebar-link>

                  <sidebar-link
                    :requiresAuthorizations="['consultar presupuesto federal']"
                    :to="{ name: 'PresupuestoFederalList' }">
                    Visualizar
                  </sidebar-link>
                </nav>
              </div>

              <a
                class="nav-link collapsed my-cursor"
                :class="activeAccordeon === 'submenu' ? '' : 'collapsed'"
                data-toggle="collapse"
                data-target="#collapseFlowsSubMenu4"
                :aria-expanded="activeAccordeon === 'submenu' ? true : false"
                aria-controls="collapseFlowsSubMenu4"
              >
                <div class="nav-link-icon"><i data-feather="layers"></i></div>
                Exportación SAACG.NET
                <div class="sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
              </a>
              <div
                :class="activeAccordeon === 'submenu' ? 'collapse show' : 'collapse'"
                id="collapseFlowsSubMenu4"
                data-parent="#accordionSidenavSub"
              >
                <nav class="sidenav-menu-nested nav" id="accordionSidenavSubSub4">
                  <sidebar-link
                    :requiresAuthorizations="['exportar estatal federal']"
                    :to="{ name: 'PlaneacionEstatalFederalExport' }"
                  >
                    Estatal/Federal/Ingresos Propios
                  </sidebar-link>

                  <sidebar-link
                    :requiresAuthorizations="['exportar ampliaciones reducciones']"
                    :to="{ name: 'PlaneacionAmpliacionesReduccionesExport' }">
                    Ampliaciones/Reducciones
                  </sidebar-link>

                  <sidebar-link
                    :requiresAuthorizations="['exportar formato traspasos']"
                    :to="{ name: 'PlaneacionTraspasosExport' }">
                    Traspasos
                  </sidebar-link>

                </nav>
              </div>

            </nav>
          </div>

          <!-- Presupuesto -->
          <a
            class="nav-link my-cursor"
            :class="activeAccordeon === 'presupuestos' ? '' : 'collapsed'"
            data-toggle="collapse"
            data-target="#collapsePresupuestos"
            :aria-expanded="activeAccordeon === 'presupuestos' ? true : false"
            aria-controls="collapsePresupuestos"
          >
            <div class="nav-link-icon"><i data-feather="users"></i></div>
            Presupuesto
            <div class="sidenav-collapse-arrow">
              <i class="fas fa-angle-down"></i>
            </div>
          </a>
          <div
            :class="activeAccordeon === 'presupuestos' ? 'collapse show' : 'collapse'"
            id="collapsePresupuestos"
            data-parent="#accordionSidenav"
          >
            <nav class="sidenav-menu-nested nav accordion" id="accordionSidenavPagesPresupuestos">
              <sidebar-link
                :requiresAuthorizations="['consultar suficiencia presupuestal']"
                :to="{ name: 'Suficiencia', params: { tabIndex: 0 } }"
              >
                Suficiencia Presupuestal
              </sidebar-link>
              <!-- <check-authorization :requiresAuthorizations="['consultar traspasos']">
                <router-link :to="{ name: 'Traspasos' }" class="nav-link">
                  Traspasos
                </router-link>
              </check-authorization> -->
              <sidebar-link
                :requiresAuthorizations="['consultar nomina']"
                :to="{ name: 'Nominas' }"
              >
                Recursos Humanos
              </sidebar-link>
            </nav>
          </div>

          <!-- Plantillas -->
          <a
            class="nav-link collapsed my-cursor"
            data-toggle="collapse"
            data-target="#collapseComponents"
            aria-expanded="false"
            aria-controls="collapseComponents"
          >
            <div class="nav-link-icon"><i data-feather="download"></i></div>
            Plantillas
            <div class="sidenav-collapse-arrow">
              <i class="fas fa-angle-down"></i>
            </div>
          </a>
          <div class="collapse" id="collapseComponents" data-parent="#accordionSidenav">
            <nav class="sidenav-menu-nested nav">
              <sidebar-link :requiresAuthorizations="[]" :to="{ name: 'PlantillasPlaneacionPresupuestos' }">
                Planeación/Presupuestos
              </sidebar-link>
            </nav>
            <nav class="sidenav-menu-nested nav">
              <sidebar-link :requiresAuthorizations="[]" :to="{ name: 'PlantillasRecursosHumanos' }">
                Recursos Humanos
              </sidebar-link>
            </nav>
          </div>

          <!-- Operaciones -->
          <div class="sidenav-menu-heading">Operaciones</div>

          <!-- Servicios Administrativos -->
          <a
            class="nav-link my-cursor"
            :class="activeAccordeon === 'polizas_sa' ? '' : 'collapsed'"
            data-toggle="collapse"
            data-target="#collapseRecursosServAdm"
            :aria-expanded="activeAccordeon === 'polizas_sa' ? true : false"
            aria-controls="collapseRecursosServAdm"
          >
            <div class="nav-link-icon"><i data-feather="users"></i></div>
            Servicios Administrativos
            <div class="sidenav-collapse-arrow">
              <i class="fas fa-angle-down"></i>
            </div>
          </a>
          <div
            :class="activeAccordeon === 'polizas_sa' ? 'collapse show' : 'collapse'"
            id="collapseRecursosServAdm"
            data-parent="#accordionSidenav"
          >
            <nav class="sidenav-menu-nested nav accordion" id="accordionSidenavPagesServAdm">
              <sidebar-link
                :requiresAuthorizations="['importar polizas sa']"
                :to="{ name: 'PolizasSAImport' }"
              >
                Importar
              </sidebar-link>
              <sidebar-link
                :requiresAuthorizations="['consultar polizas sa']"
                :to="{ name: 'PolizasSAList' }"
              >
                Listado
              </sidebar-link>
            </nav>
          </div>

          <!-- Servicios Generales -->
          <a
            class="nav-link my-cursor"
            :class="activeAccordeon === 'polizas_sg' ? '' : 'collapsed'"
            data-toggle="collapse"
            data-target="#collapseRecursosServGen"
            :aria-expanded="activeAccordeon === 'polizas_sg' ? true : false"
            aria-controls="collapseRecursosServGen"
          >
            <div class="nav-link-icon"><i data-feather="users"></i></div>
            Servicios Generales
            <div class="sidenav-collapse-arrow">
              <i class="fas fa-angle-down"></i>
            </div>
          </a>
          <div
            :class="activeAccordeon === 'polizas_sg' ? 'collapse show' : 'collapse'"
            id="collapseRecursosServGen"
            data-parent="#accordionSidenav"
          >
            <nav class="sidenav-menu-nested nav accordion" id="accordionSidenavPagesServGen">
              <sidebar-link
                :requiresAuthorizations="['importar polizas sg']"
                :to="{ name: 'PolizasSGImport' }"
              >
                Importar
              </sidebar-link>
              <sidebar-link
                :requiresAuthorizations="['consultar polizas sg']"
                :to="{ name: 'PolizasSGList' }"
              >
                Listado
              </sidebar-link>
            </nav>
          </div>

          <!-- Recursos Materiales y Servicios -->
          <a
            class="nav-link my-cursor"
            :class="activeAccordeon === 'polizas_rms' ? '' : 'collapsed'"
            data-toggle="collapse"
            data-target="#collapseRecursosMatServ"
            :aria-expanded="activeAccordeon === 'polizas_rms' ? true : false"
            aria-controls="collapseRecursosMatServ"
          >
            <div class="nav-link-icon"><i data-feather="users"></i></div>
            Recursos Materiales
            <div class="sidenav-collapse-arrow">
              <i class="fas fa-angle-down"></i>
            </div>
          </a>
          <div
            :class="activeAccordeon === 'polizas_rms' ? 'collapse show' : 'collapse'"
            id="collapseRecursosMatServ"
            data-parent="#accordionSidenav"
          >
            <nav class="sidenav-menu-nested nav accordion" id="accordionSidenavPagesRecMatServ">
              <sidebar-link
                :requiresAuthorizations="['importar polizas rms']"
                :to="{ name: 'PolizasRMSImport' }"
              >
                Importar
              </sidebar-link>
              <sidebar-link
                :requiresAuthorizations="['consultar polizas rms']"
                :to="{ name: 'PolizasRMSList' }"
              >
                Listado
              </sidebar-link>
            </nav>
          </div>

          <!-- Recursos Humanos -->
          <a
            class="nav-link my-cursor"
            :class="activeAccordeon === 'polizas_rh' ? '' : 'collapsed'"
            data-toggle="collapse"
            data-target="#collapseNominas"
            :aria-expanded="activeAccordeon === 'polizas_rh' ? true : false"
            aria-controls="collapseNominas"
          >
            <div class="nav-link-icon"><i data-feather="users"></i></div>
            Recursos Humanos
            <div class="sidenav-collapse-arrow">
              <i class="fas fa-angle-down"></i>
            </div>
          </a>
          <div
            :class="activeAccordeon === 'polizas_rh' ? 'collapse show' : 'collapse'"
            id="collapseNominas"
            data-parent="#accordionSidenav"
          >
            <nav class="sidenav-menu-nested nav accordion" id="accordionSidenavPagesNominas">
              <sidebar-link
                :requiresAuthorizations="['importar polizas rh']"
                :to="{ name: 'PolizasRHImport' }"
              >
                Importar
              </sidebar-link>
              <sidebar-link
                :requiresAuthorizations="['consultar polizas rh']"
                :to="{ name: 'PolizasRHList' }"
              >
                Listado
              </sidebar-link>
              <sidebar-link
                :requiresAuthorizations="['analisis nomina']"
                :to="{ name: 'PolizasRHAnalisisNomina' }"
              >
                Análisis Nómina
              </sidebar-link>
              <sidebar-link
                :requiresAuthorizations="['importar analitico egresos unidad administrativa']"
                :to="{ name: 'ImportAnaliticoEgresosUnidadAdministrativa' }"
                >
                Importar Analítico
              </sidebar-link>
            </nav>
          </div>

          <!-- Finanzas -->
          <a
            class="nav-link my-cursor"
            :class="activeAccordeon === 'polizas_f' ? '' : 'collapsed'"
            data-toggle="collapse"
            data-target="#collapseFinanzas"
            :aria-expanded="activeAccordeon === 'polizas_f' ? true : false"
            aria-controls="collapseFinanzas"
          >
            <div class="nav-link-icon"><i data-feather="users"></i></div>
            Dirección de Contabilidad
            <div class="sidenav-collapse-arrow">
              <i class="fas fa-angle-down"></i>
            </div>
          </a>
          <div
            :class="activeAccordeon === 'polizas_f' ? 'collapse show' : 'collapse'"
            id="collapseFinanzas"
            data-parent="#accordionSidenav"
          >
            <nav class="sidenav-menu-nested nav accordion" id="accordionSidenavPagesFinanzas">
              <sidebar-link
                :requiresAuthorizations="['importar polizas f']"
                :to="{ name: 'PolizasFinanzasImport' }"
              >
                Importar
              </sidebar-link>
              <sidebar-link
                :requiresAuthorizations="['consultar polizas f']"
                :to="{ name: 'PolizasFinanzasList' }"
              >
                Listado
              </sidebar-link>
            </nav>
          </div>

          <!-- Dirección General de Administración -->
          <a
            class="nav-link my-cursor"
            :class="activeAccordeon === 'polizas_a' ? '' : 'collapsed'"
            data-toggle="collapse"
            data-target="#collapseRecursosDirGralAdm"
            :aria-expanded="activeAccordeon === 'polizas_a' ? true : false"
            aria-controls="collapseRecursosDirGralAdm"
          >
            <div class="nav-link-icon"><i data-feather="users"></i></div>
            Dirección General de Administración
            <div class="sidenav-collapse-arrow">
              <i class="fas fa-angle-down"></i>
            </div>
          </a>
          <div
            :class="activeAccordeon === 'polizas_a' ? 'collapse show' : 'collapse'"
            id="collapseRecursosDirGralAdm"
            data-parent="#accordionSidenav"
          >
            <nav class="sidenav-menu-nested nav accordion" id="accordionSidenavPagesDirGralAdm">
              <sidebar-link
                :requiresAuthorizations="['importar polizas a']"
                :to="{ name: 'PolizasAdministracionImport' }"
              >
                Importar
              </sidebar-link>
              <sidebar-link
                :requiresAuthorizations="['consultar polizas a']"
                :to="{ name: 'PolizasAdministracionList' }"
              >
                Listado
              </sidebar-link>
            </nav>
          </div>

          <!-- Dirección de Ingresos y Egresos -->
          <a
            class="nav-link my-cursor"
            :class="activeAccordeon === 'polizas_ie' ? '' : 'collapsed'"
            data-toggle="collapse"
            data-target="#collapseRecursosDirIngEgr"
            :aria-expanded="activeAccordeon === 'polizas_ie' ? true : false"
            aria-controls="collapseRecursosDirIngEgr"
          >
            <div class="nav-link-icon"><i data-feather="users"></i></div>
            Dirección de Ingresos y Egresos
            <div class="sidenav-collapse-arrow">
              <i class="fas fa-angle-down"></i>
            </div>
          </a>
          <div
            :class="activeAccordeon === 'polizas_ie' ? 'collapse show' : 'collapse'"
            id="collapseRecursosDirIngEgr"
            data-parent="#accordionSidenav"
          >
            <nav class="sidenav-menu-nested nav accordion" id="accordionSidenavPagesDirIngEgr">
              <sidebar-link
                :requiresAuthorizations="['importar polizas ie']"
                :to="{ name: 'PolizasIEImport' }"
              >
                Importar
              </sidebar-link>
              <sidebar-link
                :requiresAuthorizations="['consultar polizas ie']"
                :to="{ name: 'PolizasIEList' }"
              >
                Listado
              </sidebar-link>
            </nav>
          </div>

          <!-- Traspasos -->
          <a
            class="nav-link my-cursor"
            :class="activeAccordeon === 'traspasos' ? '' : 'collapsed'"
            data-toggle="collapse"
            data-target="#collapseTraspasos"
            :aria-expanded="activeAccordeon === 'traspasos' ? true : false"
            aria-controls="collapseTraspasos"
          >
            <div class="nav-link-icon"><i data-feather="users"></i></div>
            Traspasos
            <div class="sidenav-collapse-arrow">
              <i class="fas fa-angle-down"></i>
            </div>
          </a>
          <div
            :class="activeAccordeon === 'traspasos' ? 'collapse show' : 'collapse'"
            id="collapseTraspasos"
            data-parent="#accordionSidenav"
          >
            <nav class="sidenav-menu-nested nav accordion" id="accordionSidenavPagesTraspasos">
              <sidebar-link
                :requiresAuthorizations="['consultar traspasos']"
                :to="{ name: 'Traspasos' }"
              >
                Movimientos
              </sidebar-link>

              <sidebar-link
                :requiresAuthorizations="['consultar traspasos']"
                :to="{ name: 'VisualizarTraspasos' }"
              >
                Visualizar
              </sidebar-link>

              <sidebar-link
              :requiresAuthorizations="['consultar traspasos']"
                :to="{ name: 'AutorizarTraspasos' }"
              >
                Autorizar/Rechazar
              </sidebar-link>
            </nav>
          </div>

          <!-- Reportes -->
          <div class="sidenav-menu-heading">Reportes</div>

          <sidebar-link
            :requiresAuthorizations="['listar bitacoras de un usuario']"
            :to="{ name: 'Historial' }"
            :show-permission-help="false"
          >
            <div class="nav-link-icon">
              <i data-feather="activity"></i>
            </div>
            Bitácoras
          </sidebar-link>

          <!-- Saldos -->
          <a
            class="nav-link my-cursor"
            :class="activeAccordeon === 'saldos' ? '' : 'collapsed'"
            data-toggle="collapse"
            data-target="#collapseSaldos"
            :aria-expanded="activeAccordeon === 'saldos' ? true : false"
            aria-controls="collapseSaldos"
          >
            <div class="nav-link-icon"><i data-feather="dollar-sign"></i></div>
            Saldos
            <div class="sidenav-collapse-arrow">
              <i class="fas fa-angle-down"></i>
            </div>
          </a>
          <div
            :class="activeAccordeon === 'saldos' ? 'collapse show' : 'collapse'"
            id="collapseSaldos"
            data-parent="#accordionSidenav"
          >
            <nav class="sidenav-menu-nested nav accordion" id="accordionSidenavPagesSaldos">
              <sidebar-link
                :requiresAuthorizations="['consultar saldos unidades administrativas']"
                :to="{ name: 'SaldosPorUnidad' }"
              >
                Por unidades administrativas
              </sidebar-link>

              <sidebar-link
                :requiresAuthorizations="['consultar saldos partidas']"
                :to="{ name: 'SaldosPorPartida' }"
              >
                Por partidas presupuestales
              </sidebar-link>

              <sidebar-link
                :requiresAuthorizations="['consultar saldos momentos']"
                :to="{ name: 'SaldosPorMomento' }"
              >
                Por momento contable
              </sidebar-link>
            </nav>
          </div>

          <!-- Reportes -->
          <a
            class="nav-link my-cursor"
            :class="activeAccordeon === 'reportes' ? '' : 'collapsed'"
            data-toggle="collapse"
            data-target="#collapseReportes"
            :aria-expanded="activeAccordeon === 'reportes' ? true : false"
            aria-controls="collapseReportes"
          >
            <div class="nav-link-icon"><i data-feather="bar-chart"></i></div>
            Reportes
            <div class="sidenav-collapse-arrow">
              <i class="fas fa-angle-down"></i>
            </div>
          </a>
          <div
            :class="activeAccordeon === 'reportes' ? 'collapse show' : 'collapse'"
            id="collapseReportes"
            data-parent="#accordionSidenav"
          >
            <nav class="sidenav-menu-nested nav accordion" id="accordionSidenavPagesReportes">
              <sidebar-link
                :requiresAuthorizations="['consultar reporte estado egresos']"
                :to="{ name: 'ReporteEstadoPptoEgresos' }"
              >
                Estado del Ejercicio del Ppto. de Egresos
              </sidebar-link>
            </nav>
            <nav class="sidenav-menu-nested nav accordion" id="accordionSidenavPagesReportes">
              <sidebar-link
                :requiresAuthorizations="['consultar reporte analitico egresos']"
                :to="{ name: 'ReporteAnaliticoPptoEgresos' }"
              >
                Estado Analítico del Ejercicio del Ppto. de Egresos
              </sidebar-link>
            </nav>
            <nav class="sidenav-menu-nested nav accordion" id="accordionSidenavPagesReportes">
              <sidebar-link
                :requiresAuthorizations="['consultar reporte auxiliar cuentas']"
                :to="{ name: 'ReporteAuxiliarCuentas' }"
              >
                Auxiliar de Cuentas
              </sidebar-link>
            </nav>
            <nav class="sidenav-menu-nested nav accordion" id="accordionSidenavPagesReportes">
              <sidebar-link
                :requiresAuthorizations="['consultar reporte saldos partidas']"
                :to="{ name: 'ReporteSaldosPartidas' }"
              >
                Saldos por Partidas de Gasto
              </sidebar-link>
            </nav>
            <nav class="sidenav-menu-nested nav accordion" id="accordionSidenavPagesReportes">
              <sidebar-link
                :requiresAuthorizations="['consultar reporte planeacion saldos partidas']"
                :to="{ name: 'ReportePlaneacionSaldosPartidas' }"
              >
                Planeación - Saldos por Partidas de Gasto
              </sidebar-link>
            </nav>
          </div>

          <!-- Importar exportar -->
          <!-- <a class="nav-link" href="#">
            <div class="nav-link-icon"><i data-feather="filter"></i></div>
            Importar/Exportar Catálogos
          </a> -->

          <!-- Ayuda -->
          <div class="sidenav-menu-heading">Ayuda</div>

          <sidebar-link
            :requiresAuthorizations="['acceso videos ayuda']"
            :to="{ name: 'VideosAyuda' }"
            :show-permission-help="true"
          >
            <div class="nav-link-icon">
              <i data-feather="video"></i>
            </div>
            Videos
          </sidebar-link>
        </div>
      </div>

      <!-- Sidenav Footer-->
      <div class="sidenav-footer">
        <div class="sidenav-footer-content">
          <div class="sidenav-footer-subtitle">Sesión iniciada como:</div>
          <div class="sidenav-footer-title">
            {{ authUser.email }}
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import SidebarLink from '@/components/Sidebar/SidebarLink.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Sidebar',

  components: {
    SidebarLink
  },

  data () {
    return {
      activeAccordeon: ''
    }
  },

  computed: {
    ...mapGetters('userModule', { authUser: 'getAuthUser' }),

    ...mapGetters('UIModule', ['getNotificationsCount'])
  },

  created () {
    const rutaActual = this.$router.currentRoute
    this.activeAccordeon = rutaActual.meta.accordeon || ''
  },

  methods: {
    async exitOfViewAs () {
      this.$store.commit('userModule/resetViewAsUserMode')
      this.$downAppWhenBannerIsActive('0rem')
      this.$store.dispatch('userModule/getAuthUser')
    }
  }
}
</script>

<style lang="scss" scoped></style>
